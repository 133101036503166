import Vue from 'vue'
import VueRouter from 'vue-router'
import  Index from '../views/index/index.vue'
import Training from '../views/training/training.vue'
import Join from '../views/join/join.vue'
import Contact from '../views/contact/contact.vue'
import Internship from '../views/internship/internship.vue'
import Contacts from '../views/contacts/contacts.vue'
import onlineServices from '../views/online-services/online-services.vue'
import About from '../views/about/about.vue'

Vue.use(VueRouter)
const routes = [
  {
    path:'/',
    name:'index',
    component: Index,
    alias:'/index'
  },
  {
    path: '/training',
    name:'training',
    component: Training
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/internship',
    name: 'internship',
    component: Internship
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
  },
  {
    path: '/online-services',
    name: 'online-service',
    component: onlineServices
  },
  {
    path: '/about',
    name: 'about',
    component: About
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
