<template>
    <div class="bottoms">
        <div class="text">
            <div>拿下高考 再谈人生</div>
            <div>For aspiration, for independence, for a better life.</div>
            <div class="record"> 
                <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备13052000号-1</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped lang="less">
@media screen and (max-width: 1600px){
    .bottoms{
        width: 100%;
        height: 211px;
        background: #1C2533;
        overflow: hidden;
        .text{
            margin-top: 69px;
            color: #fff;
            text-align: center;
            
            div:nth-child(1){
                font-size: 42px;
                font-family:'微软雅黑';
                font-weight: bold;
                letter-spacing: 15px;
            }
            div:nth-child(2){
                font-size: 21px;
                font-family: '微软雅黑';
                font-weight: 300;
            }
        }
    }
    .record{
        margin-top: 15px;
        a{
            font-size: 14px;
            color: #fff;
            text-decoration:none;
        }
    }
}
@media screen and (min-width: 1600px){
    .bottoms{
        width: 100%; 
        height: 211px; /* no */
        background: #1C2533;
        overflow: hidden;
        .text{
            margin-top: 69px; /* no */
            color: #fff;
            text-align: center;
            
            div:nth-child(1){
                font-size: 42px; /* no */
                font-family:'微软雅黑';
                font-weight: bold;
                letter-spacing: 15px; /* no */
            }
            div:nth-child(2){
                font-size: 21px; /* no */
                font-family: '微软雅黑';
                font-weight: 300;
            }
        }
    }
    .record{
        margin-top: 15px; /* no */
        a{
            font-size: 14px; /* no */
            color: #fff;
            text-decoration:none;
        }
    }
}

</style>